import Vue from 'vue'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment-timezone'

//  Turns a non-formatted phone number (ex: "12345678901") into readable number (ex: "(234) 567-8901")
Vue.filter('phone', function (value) {
    //  If the value does NOT exist, return null
    if (!value) return null

    //  Remove non-numeric
    let numeric = value.replace(/\D/g,'');

    //  Instantiate a new 'awesome-phonenumber'
    const phone = new PhoneNumber(numeric, 'US')

    //  If the phone is valid, return national format
    if (phone.isValid()) return phone.getNumber('national')

    //  Else, trim numeric value
    if (numeric.length > 10) {
        numeric = numeric.slice(value.length - 10, value.length)
    }
    
    //  Format the numeric number
    const formatted = numeric.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

    //  Use older formatter to return value
    return formatted
})
// Censors an already formatted phone [ex: (123) 456-7890 => (•••) •••• 7890]
// Pass a non-formatted phone number (ex: "1234567901") into the 'phone' filter first
Vue.filter('phoneCensor', function (value) {
    //  If the value does NOT exist, return null
    if (!value) return null

    // Phone to Array
    let phone_arr = value.split('')

    // Replace the first 7 numbers with •
    for (let n = 0; n < 9; n++) { 
        if (!isNaN(phone_arr[n])) phone_arr[n] = '•'
    }

    // Remove the dash (-) and add a space after the ')'
    phone_arr.splice(9, 1, ' ')
    phone_arr.splice(5, 0, ' ')

    // Return the phone as a string
    return phone_arr.join('')
})
//  Returns ISO string of date
Vue.filter('iso8601', function (datetime) {
    const parsed = moment(datetime).toISOString(true)
    return parsed
})
//  Remove invalid characters, trim to ten-digits
Vue.filter('phoneTrim', function (value) {
    //  If the value does NOT exist, return null
    if (!value) return null

    //  Remove non-numeric
    value = value.replace(/\D/g, '');

    if (value.length > 10) {
        value = value.slice(value.length - 10, value.length)
    }

    return value
})