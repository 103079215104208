<template>
    <v-app style="background: #F5F5F5">
        <v-main>
            <div id="content">
                <contact-form v-if="!loading" :formType="formType" :token="token"/>
            </div>
        </v-main>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            token: null,
            formType: null,
            loading: true
        }
    },
    beforeCreate() {
        // If token is not provided, redirect people to SnapDesk website
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        // If proper URL parameters are not provided, redirect to message desk site
        if((!urlParams.has('type') || !urlParams.has('token')) && (!urlParams.has('ty') || !urlParams.has('to'))) {
            window.location.href = this.$web
        }
    },
    async mounted() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        // Retrieve Token
        this.token = urlParams.get('token') || urlParams.get('to')
        this.formType = urlParams.get('type') ||  urlParams.get('ty')

        this.loading = false
    },
}
</script>
<style>
@font-face {
    font-family: 'Roboto';
    src: local('src/assets/fonts/Roboto-Regular.ttf') format("truetype");
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: gt-alpina;
    src: local('assets/fonts/GT-Alpina-Condensed-Regular.woff') format("woff");
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'sofia-pro';
    src: local('assets/fonts/Sofia Pro Regular Az.otf') format("opentype");
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'sofia-bold';
    src: local('assets/fonts/Sofia Pro Semi Bold Az.otf') format("opentype");
    font-weight: bold;
    font-display: swap;
}

body {
    overflow: hidden;
    font-family: sans-serif;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
html {
    background-color: #f5f5f5 !important;
    overflow: hidden !important;
    overflow-y: hidden !important;
    height: -webkit-fill-available;
}
body::-webkit-scrollbar {
    display: none;
}
a {
    color: inherit;
    text-decoration: none;
}
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
    border: 1px solid #d5d5d5;
}
::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
}
::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #b0b0b0;
}
#content {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
div.v-input__slot div.v-input--selection-controls__input {
    height: 16px !important;
    width: 16px !important;
}
input[type="checkbox"] {
    opacity: 1 !important;
}
div.v-input--checkbox > div.v-input__control > div.v-input__slot > label {
    line-height: 1.2em;
    font-size: 12px !important;
}
</style>
