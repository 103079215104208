import Vue from 'vue'

/**
 * IMAGES:
 * 
 * Register app images here.  $images is attached to the Vue instance
 */
const baseUrl = `assets/media/images`
const images = {
    logo: `${baseUrl}/messagedesk-combined-horizontal-purple-white.svg`,
    org: `${baseUrl}/org.svg`,
    team: `${baseUrl}/team.svg`,
    poweredBy: `${baseUrl}/powered-by.svg`,
}

Vue.prototype.$images = images

export default images
