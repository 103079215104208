import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify';
import 'vue-datetime/dist/vue-datetime.css'

// Plugins
import '@/components/components.js'
import '@/plugins/vee-validate'
import '@/plugins/filters'
import '@/plugins/images.js'
import '@/plugins/helpers.js'

Vue.config.productionTip = false

//  Assign globals
Vue.prototype._ = require('lodash')
Vue.prototype.$api = process.env.VUE_APP_TEST_URL || `${process.env.VUE_APP_URL_PROTOCOL}://api.${process.env.VUE_APP_URL_BASE}`
Vue.prototype.$web = `${process.env.VUE_APP_URL_PROTOCOL}://www.${process.env.VUE_APP_WEB_BASE}`
Vue.prototype.$app = `${process.env.VUE_APP_URL_PROTOCOL}://app.${process.env.VUE_APP_URL_BASE}`

//  Set Environment
Document.title = process.env.VUE_APP_NAME
Vue.config.productionTip = false

window.addEventListener('message', event => {
  // IMPORTANT: check the origin of the data! 
  if (event.origin.startsWith(Vue.prototype.$app)) {
    if (event.data === 'refresh') window.location.reload()
  }
});

const vm = new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app')

Window.root = vm
