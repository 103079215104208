<template>
    <div id="contact-form" v-if="form && verified"
        :class="{'mobile' : isMobile, 'desktop' : !isMobile}" :style="stylesContactForm">
        <v-progress-linear v-if="submitting" rounded indeterminate absolute
            :color="formColor" />
        <div v-if="!submitted">
            <div v-if="height > 450">
                <h1 class="mainTitle" :style="stylesFormTitle">
                    {{ stylesForm.form.title.value }}
                </h1>
                <img v-if="formImageSrc" alt="form" :src="formImageSrc"
                    class="form-image" />
            </div>
            <div v-if="dynamicInputs" class="inputs"
                :style="`justify-content:${formInputStyles['justify-content']};`">
                <v-fade-transition origin="bottom center 0">
                    <div v-if="editedError"
                        style="color:#FF0033; font-style:italic; margin-bottom: 0.8em;">
                        To save, edit some fields
                    </div>
                </v-fade-transition>
                <app-form
                    :style="$vuetify.breakpoint.xsOnly ? `width:100%;` : `width:${formInputStyles.width}%;`"
                    :color="formColor" :inputs="dynamicInputs" :model="person"
                    @submit="submit" :dense="false" :buttonDisabled="submitting"
                    :buttonText="stylesButton.value"
                    :buttonTextColor="stylesButton['text-color']"
                    :buttonColor="stylesButton.color"
                    :buttonBorderRadius="stylesButton['border-radius']" />
            </div>
        </div>
        <div v-else>
            <img v-if="successImageSrc" alt="team" :src="successImageSrc"
                class="form-image" />
            <h2 class="mainTitle" :style="stylesSuccessTitle">
                {{ stylesForm.success.title.value }}
            </h2>
            <h3 class="subTitle" :style="stylesSuccessSubtitle">
                {{ stylesForm.success.message.value }}
            </h3>
        </div>

        <app-footer v-if="displayFooter" />
    </div>
</template>

<script>
const _ = require('lodash')
import contactForm from '@/mixins/contact-form-mixin.js'
export default {
    mixins: [contactForm],
    data() {
        return {
            // Determines if the not edited error message is shown
            editedError: false,

            // Tenant data
            tenant: null,

            // The submitted flag
            submitted: false,

            // The verified flag
            verified: false,

            //  The person model - used with contact (update) form
            person: { extensions: {} },

            // Flag that determines if we are submitting
            submitting: false,

            // The form
            form: null
        }
    },
    created() {
        this.init()
    },
    props: {
        title: {
            type: String,
            required: false,
            default: `Update Your Contact Information`
        },
        formType: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        },
        formColor: {
            type: String,
            required: false,
            default: '#6142B0'
        }
    },
    computed: {
        isContactForm() {
            const type = this.formType.toLowerCase().trim()
            return type === 'contactform' || type === 'c'
        },
        isDynamicForm() {
            const type = this.formType.toLowerCase().trim()
            return type === 'dynamicform' || type === 'd'
        },
        height() {
            return this.$vuetify.breakpoint.height
        },
        dynamicInputs() {
            const cols =
                this.formInputStyles.columns === 1
                    ? 12
                    : this.formInputStyles.columns === 2
                    ? 6
                    : this.formInputStyles.columns === 3
                    ? 4
                    : 12

            const inputs = [...this.form.fields]

            inputs.push({
                type: 'CHECKBOX',
                name: 'agreement',
                label: `By submitting the form you agree to receive promotional text messages from ${
                    this.tenant.company_name || 'MessageDesk'
                }. You may opt-out at anytime by replying STOP.`,
                path: 'phone_agreement'
            })

            inputs.forEach((input) => {
                if (input.required === true && !input.validate.includes('|required')) {
                    input.validate = input.validate.concat('|required')
                }

                if (input.type.toLowerCase() === 'number') {
                    input.type = 'TEXT'
                    if (!input.validate.includes('|numeric')) {
                        input.validate = input.validate.concat('|numeric')
                    }
                } else if (input.type.toLowerCase() === 'phone') {
                    input.type = 'TEXT'
                    if (!input.validate.includes('|phone')) {
                        input.validate = input.validate.concat('|phone')
                    }
                }

                input['background-color'] = this.formInputStyles.props['background-color']
                input.height = this.formInputStyles.props.height
                input.color = this.formInputStyles.props.color
                input.cols = cols
                input.dark = this.formInputStyles.props.dark
                input.dense = this.formInputStyles.props.dense
                input.filled = this.formInputStyles.props.filled
                input.outlined = this.formInputStyles.props.outlined
                input.rounded = this.formInputStyles.props.rounded
                input.shaped = this.formInputStyles.props.shaped
                input.colStyle = 'padding:0 3px !important;'
            })

            const n = inputs.length
            if (cols === 4) {
                if (n % 3 === 1) {
                    inputs[n - 1].cols = 12
                } else if (n % 3 === 2) {
                    inputs[n - 1].cols = 6
                    inputs[n - 2].cols = 6
                }
            } else if (cols === 6) {
                if (n % 2 === 1) {
                    inputs[n - 1].cols = 12
                }
            }

            inputs[inputs.length - 1].cols = 12

            return inputs
        },
        isMobile() {
            return (
                this.$vuetify.breakpoint.xsOnly ||
                /android.+mobile|ip(hone|[oa]d)/i.test(navigator.userAgent)
            )
        },
        displayFooter() {
            return this.stylesForm.footer.display
        },
        formImageSrc() {
            if (this.stylesForm.form.image.src) {
                return this.stylesForm.form.image.src
            }

            if (
                this.stylesForm.form.image.useDefault === false ||
                this.stylesForm.form.image === false
            ) {
                return null
            }

            return this.$images.org
        },
        formInputStyles() {
            return this.stylesForm.form.inputs
        },
        logoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm':
                    return 70
                case 'xs':
                    return 60
            }
            return 80
        },
        successImageSrc() {
            if (this.stylesForm.success.image.src) {
                return this.stylesForm.success.image.src
            }

            if (this.stylesForm.success.image.useDefault === false) {
                return null
            }

            return this.$images.team
        },
        stylesButton() {
            return this.stylesForm.form.button
        },
        stylesContactForm() {
            return `background-color:${this.stylesForm.page.inner['background-color']}; background-image:url(${this.stylesForm.page.inner['background-image']}); height:${this.pageHeight};`
        },
        stylesForm() {
            return this.form.styles
        },
        stylesFormTitle() {
            return `color:${this.stylesForm.form.title.color}; letter-spacing:${this.stylesForm.form.title['letter-spacing']}px; font-size:${this.stylesForm.form.title.size}px`
        },
        stylesSuccessSubtitle() {
            return `color:${this.stylesForm.success.message.color}; letter-spacing:${this.stylesForm.success.message['letter-spacing']}px; font-size:${this.stylesForm.success.message.size}px`
        },
        stylesSuccessTitle() {
            return `color:${this.stylesForm.success.title.color}; letter-spacing:${this.stylesForm.success.title['letter-spacing']}px; font-size:${this.stylesForm.success.title.size}px`
        }
    },
    methods: {
        init() {
            if (this.isContactForm) {
                // Verify token through API and retrieve cached data
                this.verifyToken(this.token).then((tokenCache) => {
                    if (tokenCache) {
                        // Set the "formVerified" and "formSubmitted" state
                        this.verified = tokenCache.verified === 'True'
                        this.submitted = tokenCache.submited === 'True'
                    }
                    // Else, if the token is not verified, redirect
                    else if (!tokenCache || !this.verified) {
                        window.location.href = this.$web
                    }

                    // Get the contact 'update' form
                    this.contactFormDataGet(this.token).then((res) => {
                        // If the contact form was not found, redirect to website
                        if (!res) window.location.href = this.$web

                        // Set the form
                        this.form = res.form

                        // Set the tenant
                        this.tenant = res.tenant

                        // Load the person model
                        this.loadModel(this.form.fields)

                        // Get the extensions
                        res.person.extensions = res.person.extensions.reduce(
                            (acc, cur) => {
                                acc[cur.option.name] = cur.value
                                return acc
                            },
                            {}
                        )

                        // Assign the person
                        Object.assign(this.person, res.person)
                    })
                })
            } else if (this.isDynamicForm) {
                // Get the dynamic 'create' form
                this.dynamicFormDataGet(this.token).then((res) => {
                    // If the dynamic form was not found, redirect to website
                    if (!res) window.location.href = this.$web

                    // Set the form
                    this.form = res.form

                    // Set the tenant
                    this.tenant = res.tenant

                    // Load the model
                    this.loadModel(res.form.fields)

                    // Verify the form
                    this.verified = true
                })
            }
        },
        loadModel(fields) {
            const re = /^(.*?)\./
            fields.forEach((field) => {
                let f = re.exec(field.path) || field.path

                if (typeof f === 'object') f = f[1]

                if (this.personModel.has(f)) {
                    this.person[f] = this.personModel.get(f)
                } else {
                    if (f === 'extensions') {
                        objectPath(this.person, field.path, null)
                    } else if (f) {
                        this.person.extensions[f] = null
                        field.path = `extensions.${field.path}`
                    }
                }
            })
            this.person.phone_agreement = false
        },
        submit(payload) {
            // If the phone agreement is not true, return
            if (!payload.phone_agreement) return

            // Indicate that we are submitting
            this.submitting = true

            if (this.isContactForm) {
                payload['token'] = this.token

                this.contactFormPushData({ person: payload }).then((res) => {
                    this.submitted = true
                    this.submitting = false
                })
            } else if (this.isDynamicForm) {
                this.form.token = this.token
                this.dynamicFormPushData({
                    person: payload,
                    token: this.token
                }).then((res) => {
                    this.submitted = true
                    this.submitting = false
                })
            }
        }
    }
}
</script>

<style scoped>
#contact-form {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-flow: column nowrap;
    position: relative;

    max-width: 100vw;

    background-origin: border-box;
    background-position: start;
    background-size: contain;
    overflow: hidden auto;

    text-align: center;

    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.mobile {
    padding: 48px 0 81px 0;
    height: 100vh;
}
.desktop {
    width: min(80vw, 800px);
    height: 90% !important;
    border-radius: 12px !important;
}
.inputs {
    display: flex;
    justify-content: center;
    padding: 0 1rem 0 1rem;
}
.mainTitle {
    padding: 1rem 2rem;
    max-width: 100%;
    word-wrap: break-word;
}
.subTitle {
    padding: 0.1rem 2.5rem;
    max-width: 100%;
    word-wrap: break-word;
}
.form-image {
    max-height: 33vh;
    width: auto;
}
</style>
