import Vue from 'vue'

import AppForm from './AppForm.vue'
import AppFooter from './AppFooter'
import ContactForm from './ContactForm'
import { Datetime } from 'vue-datetime'

Vue.component('app-form', AppForm)
Vue.component('contact-form', ContactForm)
Vue.component('app-footer', AppFooter)
Vue.component('datetime', Datetime)
