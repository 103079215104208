import VeeValidate from 'vee-validate'
import Vue from 'vue'
import PhoneNumber from 'awesome-phonenumber'
import { availableAreaCodes } from './areacodes.js'

VeeValidate.Validator.extend('accept_terms', {
    getMessage: field => 'Please accept Terms & Conditions',
    validate: (value) => {
        return value !== false
    }
})

VeeValidate.Validator.extend('alpha_num_spaces', {
    getMessage: field => `${field} must contain letters, numbers, and spaces only`,
    validate: (value) => {
        return /^[\w\-\s]+$/.test(value)
    }
})

VeeValidate.Validator.extend('areacode', {
    getMessage: field => {
        // return '800 Area Codes are disabled'
        return 'This area code is not currently supported.'
    },
    validate: (value) => {
        //  Grab our available area codes
        const available = availableAreaCodes.codes
        //  Check if the value matches any of these codes
        const matches = available.includes(parseInt(value))
        //  If it does, passes
        return matches
    }
})

VeeValidate.Validator.extend('decimal', {
    getMessage: field => `${field} must contain numbers only`,
    validate: (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }
})

VeeValidate.Validator.extend('message_length', {
    getMessage: field => 'Message too long',
    validate: (value, params) => {
        //  Get the total character limit
        const limit = params[0]

        //  Check if the text length is under the limit
        const lengthAllowed = value.length <= limit

        //  return the result
        return lengthAllowed
    }
})

VeeValidate.Validator.extend('optionalEmail', {
    getMessage: field => 'Must be a valid email address.',
    validate: (value) => {
        // Allow empty field through validation (value is null or empty string "")
        return (value === null || value === "") ? true : window.emailRegex.test(value)
    }
});

VeeValidate.Validator.extend('phone', {
    getMessage: field => `${field} is not a valid phone number`,
    validate (value) {
        if (!value) return true

        //  Remove non-numeric
        value = value.replace(/\D/g,'');

        return new Promise(resolve => {
            const phone = new PhoneNumber(value, 'US');

            //  Check if the phone is valid
            const isValid = phone.isValid()

            //  Return result
            resolve({ valid: isValid })
        })
    }
})

VeeValidate.Validator.extend('is_not_keyword', {
    getMessage: keyword => `The 'unsubscribe' keyword must be different from the 'subscribe' keyword.`,
    validate (value, params) {
        return value !== params[0];
    }
})

VeeValidate.Validator.extend('verify_password', {
    getMessage: field => 'Passwords must be at least 8 characters and contain 1 uppercase letter, 1 lowercase letter, 1 number, and one special character.',
    validate: value => {
        var synopsysRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})(?!.*([A-Za-z0-9])\\1{1})')
        var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
        return strongRegex.test(value)
    }
})

VeeValidate.Validator.extend('verify_url', {
    getMessage: field => 'Web address must use https:// format in URL.',
    validate: value => {
        var urlRegex = new RegExp(/^(https?:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)
        return urlRegex.test(value) || value === null || value===''
    }
})

Vue.use(VeeValidate)

export default VeeValidate
