<template>
    <div id="footer-container">  
        <div id="footer">
            <a :href="referralLink" rel="author" target="_blank">
                <v-img alt="messagedesk" contain style="margin:0 auto;"
                    :height="`${logoHeight}px`" max-width="245px" width="auto"
                    :src="$images.poweredBy" />
            </a>       
        </div>
    </div>
</template>
            
<script>
export default {
    data() {
        return {
            referralLink: 'https://snapdesk.app/?utm_source=messagedesk&utm_medium=anonymousForm&utm_campaign=formReferral',
        }
    },
    computed: {
        logoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm' : return 70
                case 'xs' : return 60
            }
            return 80
        }
    }
}
</script>

<style scoped>
#footer {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    line-height: 0; 
    font-style: italic;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0 5px 0 !important;
}

#footer p {
    font-size: 0.8rem;
    color: black;
    text-decoration: none;
}

#footer-container {
    display: inline-flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    order: 2;
}
</style>
