<template>
    <v-form class="form" v-if="temp" @submit.prevent="submit">
        <v-row no-gutters class="px-4 pb-4">
            <div v-if="description" class="mb-3"> {{ description }} </div>
            <template class="list-inputs" v-for="(input, i) in inputs">
                <v-col :key="i" :cols="input.cols || 12" class="pa-0" :style="input.colStyle">

                    <div v-if="input.type === 'CHECKBOX' && (input.visible ? getInputValue(input.visible) : true)">
                        <v-checkbox :name="input.name"
                            :label="input.label" :disabled="input.disabled || false"
                            :color="input.color"
                            :dark="input.dark || false" :dense="input.dense || false"
                            :input-value="getObjectValue(input)"
                            @change="setObjectValue($event ? true : false, input)"
                            v-validate="getInputValue(input.validate)"
                            :error-messages="errors.collect(input.name)"
                            class="ma-0" style="font-size:12px; line-height:1.2em;" />
                    </div>

                    <div v-if="input.type === 'DATE' && (input.visible ? getInputValue(input.visible) : true)"
                        :class="input.class" class="mb-6">
                        <v-text-field :label="input.label" :name="input.name"
                            v-validate="getInputValue(input.validate)"
                            :style="input.style"
                            :disabled="input.disabled || false"
                            :error-messages="errors.collect(input.name)"
                            :background-color="input['background-color']" :color="input.color"
                            :dark="input.dark || false" :dense="input.dense || false"
                            :filled="input.filled || false" :outlined="input.outlined || false"
                            :rounded="input.rounded || false" :shaped="input.shaped || false"
                            :height="input.height || 20"
                            :value="getObjectValue(input)"
                            :prepend-icon="input.prependIcon" style="pointer-events:none"
                            tabindex="-1">
                            <template v-slot:append-outer>
                                <v-btn v-if="input.appendIcon" icon small
                                    @click="$emit('click:append', temp)"
                                    style="pointer-events: auto">
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <datetime
                            style="width:calc(100% - 60px); max-height:32px; padding:0px; margin-left:30px; margin-right:30px;"
                            input-style="width:100%; height:32px; cursor:pointer;"
                            type="date" :value="getObjectValue(input)"
                            :value-zone="tz" :zone="tz"
                            @input="setDateValue($event, input)">
                        </datetime>
                    </div>

                    <div v-if="input.type === 'DATETIME' && (input.visible ? getInputValue(input.visible) : true)"
                        :class="input.class">
                        <v-text-field :label=" input.label" :name="input.name"
                            v-validate="getInputValue(input.validate)"
                            :disabled="input.disabled || false"
                            :style="input.style"
                            :background-color="input['background-color']" :color="input.color"
                            :dark="input.dark || false" :dense="input.dense || false"
                            :filled="input.filled || false" :outlined="input.outlined || false"
                            :rounded="input.rounded || false" :shaped="input.shaped || false"
                            :height="input.height || 20"
                            :error-messages="errors.collect(input.name)"
                            :value="dateTime(getObjectValue(input))"
                            :prepend-icon="input.prependIcon"
                            :append-outer-icon="input.appendIcon"
                            @click:append-outer="$emit('click:append', temp)"
                            style="pointer-events:none" tabindex="-1">
                        </v-text-field>
                        <datetime input-style="width:100%; height:32px;  cursor:pointer"
                            type="datetime" :value="getObjectValue(input)"
                            :value-zone="tz" :zone="tz"
                            @input="setObjectValue($event, input)" use12-hour>
                        </datetime>
                    </div>

                    <div v-if="input.type === 'LABEL' && (input.visible ? getInputValue(input.visible) : true)"
                        v-html="getInputValue(input.label)" :class="input.class" />

                    <persons-search-bar
                        v-if="input.type === 'PERSONS_SEARCH' && (input.visible ? getInputValue(input.visible) : true)"
                        :label="input.label" :color="color" :class="input.class"
                        :icon="input.icon"
                        :route="input.route" @submit="onPersonSelected($event, input)"
                        class="mb-3" />

                    <v-text-field
                        v-if="input.type === 'PHONE' && (input.visible ? getInputValue(input.visible) : true)"
                        :class="input.class" v-mask="'(###) ###-####'"
                        :style="input.style"
                        :disabled="input.disabled || false"
                        :background-color="input['background-color']" :color="input.color"
                        :dark="input.dark || false" :dense="input.dense || false"
                        :filled="input.filled || false" :outlined="input.outlined || false"
                        :rounded="input.rounded || false" :shaped="input.shaped || false"
                        :height="input.height || 20"
                        :prepend-icon="input.prependIcon" :name="input.name"
                        :label="input.label" v-model="phones[i]"
                        v-validate="getInputValue(input.validate)"
                        :error-messages="errors.collect(input.name)"
                        @input="setPhoneValue($event, input)" />

                    <v-select
                        v-if="(input.type === 'SELECT' || input.type === 'DROPDOWN') && (input.visible ? getInputValue(input.visible) : true)"
                        :class="input.class" :name="input.name" :label="input.label"
                        :style="input.style"
                        :disabled="input.disabled || false"
                        :prepend-icon="input.prependIcon"
                        :background-color="input['background-color']" :color="input.color"
                        :dark="input.dark || false" :dense="input.dense || false"
                        :filled="input.filled || false" :outlined="input.outlined || false"
                        :rounded="input.rounded || false" :shaped="input.shaped || false"
                        :height="input.height || 20"
                        v-validate="getInputValue(input.validate)"
                        :error-messages="errors.collect(input.name)"
                        :item-text="input.itemText" :item-value="input.itemValue"
                        :items="input.options" @change="setObjectValue($event, input)"
                        v-model="input.selected" />

                    <div v-if="input.type === 'MESSAGE_TEXT_FORM'">
                        <message-text-form :insert="getObjectValue(input)"
                            :print="'print' in input && input.print"
                            @input="setObjectValue($event, input)" />
                    </div>

                    <v-textarea
                        v-if="input.type ==='TEMPLATE_BODY' && (input.visible ? getInputValue(input.visible) : true)"
                        :class="input.class" :name="input.name" :label="input.label"
                        :color="color"
                        :counter="input.validate.includes('max:160') ? 160 : 960"
                        v-validate="getInputValue(input.validate)"
                        :error-messages="errors.collect(input.name)"
                        :value="getObjectValue(input)" outlined max-width="100%"
                        max-height="250px" @input="setObjectValue($event, input)"
                        ref="templateBody">
                        <template v-slot:append>
                            <div style="width:36px; display:unset">
                                <message-tag-menu icon left :isInvoice="true"
                                    @onTagSelected="insertObjectValue($event, input)"
                                    style="margin-top:-15px; margin-right:-10px; width:42px;" />
                                <message-emoji-menu class="mt-1" style="width:42px;"
                                    @onEmojiSelected="insertObjectValue($event, input)" />
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="sendTestText" v-on="on"
                                            class="mt-1" rounded depressed outlined icon>
                                            <v-icon>mdi-comment-check-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Send Test Text</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-textarea>

                    <v-text-field
                        v-if="!input.mask && input.type === 'TEXT' && (input.visible ? getInputValue(input.visible) : true)"
                        :class="input.class" :name="input.name" :label="input.label"
                        :disabled="input.disabled || false" :value="getObjectValue(input)"
                        v-validate="getInputValue(input.validate)"
                        :data-vv-name="input.name"
                        :style="input.style"
                        :background-color="input['background-color']" :color="input.color"
                        :dark="input.dark || false" :dense="input.dense || false"
                        :filled="input.filled || false" :outlined="input.outlined || false"
                        :rounded="input.rounded || false" :shaped="input.shaped || false"
                        :height="input.height || 20"
                        :placeholder="input.placeholder"
                        :error-messages="errors.collect(input.name)"
                        @input="setObjectValue($event, input)"
                        :prepend-icon="input.prependIcon" />

                    <v-textarea
                        v-if="input.type === 'TEXTAREA' && (input.visible ? getInputValue(input.visible) : true)"
                        :style="input.style"
                        :class="input.class" :name="input.name" :label="input.label"
                        :disabled="input.disabled || false"
                        :prepend-icon="input.icon"
                        :placeholder="input.placeholder"
                        :background-color="input['background-color']" :color="input.color"
                        :dark="input.dark || false" :dense="input.dense || false"
                        :filled="input.filled || false" :outlined="input.outlined || false"
                        :rounded="input.rounded || false" :shaped="input.shaped || false"
                        :height="input.height || 20"
                        v-validate="getInputValue(input.validate)"
                        :error-messages="errors.collect(input.name)"
                        :value="getObjectValue(input)" auto-grow max-width="100%"
                        @input="setObjectValue($event, input)"/>

                </v-col>
            </template>
            <v-row v-if="button" id="submitRow">
                <v-btn :style="`margin:0.5rem; color:${buttonTextColor}; border-radius:${buttonBorderRadius}px`" elevation="0" :color="buttonColor" 
                    type="submit" :disabled="!edited || buttonDisabled" @click="submit" :loading="buttonDisabled">
                    {{ buttonText }}
                </v-btn>
            </v-row>
        </v-row>
    </v-form>
</template>
<script>
const  _ = require('lodash');
import moment from 'moment'
export default {
    data() {
        return {
            //  Tracks if the form has been edited
            edited: false,
            //  The temporay model we are updating/creating
            temp: null,
            //  An array of v-models for validating phones
            phones: [],

            phoneAgreementError: true
        }
    },
    props: {
        //  Determines if the save button is shown
        button: {
            type: Boolean,
            default: true
        },
        buttonText: {
            type: String,
            default: "Save",
            required: false
        },
        buttonTextColor: {
            type: String,
            default: "#FFFFFF",
            required: false
        },
        buttonColor: {
            type: String,
            default: "#6142B0",
            required: false,
        },
        buttonBorderRadius: {
            type: Number,
            default: 28,
            required: false
        },
        //  Determines if the button is disabled
        buttonDisabled: {
            type: Boolean,
            default: false,
        },
        //  The color theme of the form
        color: {
            type: String,
            default: 'primary'
        },
        //  Determines if inputs are dense are not
        dense: {
            type: Boolean,
            default: false
        },
        //  Description of the form
        description: {
            type: String,
            default: null
        },
        //  The inputs to include in the form
        inputs: {
            type: Array,
            default: null,
            required: true
        },
        //  Determines if the app dialog is closed when the 'save' button is pressed on an unedited form
        persist: {
            type: Boolean,
            default: false
        },
        //  The object we are updating/creating
        model: {
            type: [Object],
            required: true
        },
        timeZone: {
            type: String,
            required: false,
            default: 'UTC'
        }
    },
    mounted() {
        //  Create a deep clone for editing
        this.temp = _.cloneDeep(this.model)

        //  Initialize v-models for phone validation
        this.phonesInit()
    },
    computed: {
        btnTopMargin() {
            if (this.$vuetify.breakpoint.name == 'xs') {
                return '0'
            }   
            return '3'
        },
        requiredFieldsFilled() {
            if (this.inputs) {
                let valid = true
                this.inputs.forEach((input) => {
                    if (input.required && !this.getObjectValue(input)) valid = false
                })
                return valid
            }
            return true
        },
        tz() {
            return moment().tz(this.timeZone).format('z')
        }
    },
    methods: {
        //  Returns date in format "MMM D, YYYY, hh:mm am/pm" (ex: Jun 19, 2020, 09:51 am) - very similar to chatDate
        dateTime(value) {
            if (!value) return null
            const tz = this.timeZone
            const timezone = moment().tz(tz).format('z')
            const time = moment.utc(value).local().format('MMM D, YYYY, hh:mma')
            return `${time} (${timezone})`
        },
        //  Append an object value with input (used for inserting tags)
        insertObjectValue(tag, input) {
            //  Get the current value of the model
            const val = this.getObjectValue(input)

            //  Get position of cursor
            const cursorPos = this.$refs.templateBody[0].$refs.input.selectionStart

            //  Get part of message before cursor
            let constructMessage = val.slice(0, cursorPos)

            //  If there's no space before tag position, add one
            if (!(constructMessage[constructMessage.length - 1] === ' '))
                constructMessage += ' '

            //  Insert tag
            constructMessage += tag

            //  Add part of message after cursor
            constructMessage += val.slice(cursorPos)

            //  Set message text to constructed message
            this.setObjectValue(constructMessage, input)
        },
        //  Get a value from temp, using a dot-notated path
        getObjectValue(input) {
            //  If the input path does NOT exist, do nothing
            if (!input.path) return

            //  Else, get the current value
            const val = this.objectGet(input.path, this.temp)

            //  Return the value
            return val
        },
        //  Get the value from the [function,string,bool,int] bound to the input
        getInputValue(input) {
            //  If the input type is 'function', we pass the current object into the function
            if (typeof input === 'function') return input(this.temp)

            //  Else, the input is a value, so return it
            return input
        },
        //  Add a chip to temp's chips array
        onChipAdded(chip, input) {
            //  A chip was added, so update edited value
            this.edited = true

            //  Fetch the chips from temp
            const chips = this.getObjectValue(input)

            //  If the chip doesn't already exist, add it
            if (chips.find((x) => x.id === chip.id)) {
                this.$store.dispatch('dialogOpen', {
                    type: 'MESSAGE',
                    title: 'Oops',
                    icon: 'mdi-alert',
                    text: `That ${chip.object} is already in the list.`
                })
            } else {
                //  Add the chip
                chips.push(chip)
            }
        },
        //  Remove a chip from temp's chips array
        onChipRemoved(chip, input) {
            //  A chip was removed, so update edited value
            this.edited = true

            //  Fetch the chips from temp
            let chips = this.getObjectValue(input)

            //  Remove the matching chip from the list
            chips = chips.filter((item) => item.id !== chip.id)

            this.setObjectValue(chips, input)
        },
        //  Emit onPersonSelected to parent (unique to persons-autocomplete)
        onPersonSelected(person, input) {
            //  If the autocomplete is bound to chips, add the person to the chips
            if (input.chips) {
                this.onChipAdded(person, input)
            }

            //  Emit onPersonSelected to parent
            this.$emit('run', 'onPersonSelected', person)
        },
        //  Create a v-model for each phone input, this is used for validation purposes
        phonesInit() {
            //  Iterate through each input
            for (let i = 0; i < this.inputs.length; i++) {
                //  Reference the input
                const input = this.inputs[i]

                //  If the input type is phone...
                if (input.type === 'PHONE') {
                    //  Get the value
                    const val = this.getObjectValue(input)

                    //  Push the filtered phone value to the v-model
                    this.phones[i] = this.$options.filters.phoneTrim(val)
                }
            }
        },
        //  Redirects user to their own channel with a message text set to their template body
        async sendTestText() {
            //  Find the user's channel
            const channel = await this.$store.dispatch(
                'channelsGet',
                this.$store.getters.user.person.primary_channel_id
            )

            //  Open message preview
            this.$store.dispatch('dialogOpen', {
                type: 'APP',
                title: 'Test Text',
                icon: 'mdi-message-reply-text',
                color: 'green',
                component: 'message-preview',
                props: {
                    channel: channel,
                    message: {
                        //  Assuming the model will always be a template when sending a test text
                        text: `[TEST] ${this.temp.message_body}`,
                        channel_id: channel.id,
                        outbound: true
                    },
                    type: 'send',
                    callback: () => {
                        console.log('Test Text Sent.')
                    }
                },
                closeable: true
            })
        },
        //  Set a value on temp, using a dot-notated path
        setObjectValue(value, input) {
            //  If the value is an 'invalid date' from the datetime plugin, do NOT write the value
            if (value === 'Invalid date' && input.type === 'DATE') return

            //  Set 'edited' to true, since a change is happening
            this.edited = true

            //  If the path and model exist, set the value
            if (input.path && this.temp) {
                this.objectSet(input.path, value, this.temp)
            }
        },
        // Set a value on the date object only if it is valid
        setDateValue(value, input) {
            //  Set 'edited' to true, since a change is happening
            this.edited = true

            // Value can be null
            if (!value) this.setObjectValue(value, input)

            //  Format the date for the API
            const val = moment(value).utc().format('MM/DD/YYYY')

            //  Set the value
            this.setObjectValue(val, input)
        },
        //  Set the value of a phone field
        setPhoneValue(value, input) {
            //  Unmask the value
            let numeric = this.unmask(value, '(###) ###-####')

            //  Add the north american country code ONLY IF VALUE EXISTS
            numeric = numeric ? `1${numeric}` : null

            //  Set the phone value
            this.setObjectValue(numeric, input)
        },
        //  Submit the form to its parent
        async submit() {
            if (this.temp.hasOwnProperty('phone_agreement') && this.temp.phone_agreement !== true) {
                return
            }

            const valid = await this.$validator.validate()

            //  If the form is NOT valid, do nothing
            if (!valid) return

            return this.$emit('submit', this.temp)
        },
        //  Use dot notated path to get a value from a nested object
        objectGet(path, obj = self, separator = '.') {
            var properties = Array.isArray(path) ? path : path.split(separator)
            return properties.reduce((prev, curr) => prev && prev[curr], obj)
        },
        objectSet (path, value, obj) {
            // this is a super simple parsing, you will want to make this more complex to handle correctly any path
            // it will split by the dots at first and then simply pass along the array (on next iterations)
            let properties = Array.isArray(path) ? path : path.split('.')

            // Not yet at the last property so keep digging
            if (properties.length > 1) {
                // The property doesn't exists OR is not an object (and so we overwritte it) so we create it
                if (!obj.hasOwnProperty(properties[0]) || typeof obj[properties[0]] !== 'object')
                    obj[properties[0]] = {}
                // We iterate.
                return this.objectSet(properties.slice(1), value, obj[properties[0]])
                // This is the last property - the one where to set the value
            } else {
                // We set the value to the last property
                obj[properties[0]] = value
                return true // this is the end
            }
        }
    }
}
</script>
<style scoped>
.vdatetime {
    font-size: 0;
    height: 32px;
    margin-top: -60px;
    padding-bottom: 50px;
    border: 0;
    border-radius: 20px;
}
.form {
    text-align: center;
    font-size: 4px !important;
}
#submitRow {
    justify-content: space-evenly;
    align-items: center
}
</style>
