export default {
    data() {
        return {
            personModel: new Map([
                ['company', null],
                ['name_title', null],
                ['name_given', null],
                ['name_middle', null],
                ['name_family', null],
                ['name_suffix', null],
                ['name_full', null],
                ['website', null],
                ['notes', null],
                ['phone_default', {
                    'free_form_number': null,
                    'default': true,
                    'type': 'MOBILE'
                }],
                ['email_default', {
                    'address': null,
                    'default': true
                }],
                ['address_billing', {
                    'default_billing': true,
                    'default_shipping': false,
                    'line1': null,
                    'line2': null
                }],
                ['address_shipping', {
                    'default_billing': false,
                    'default_shipping': true,
                    'line1': null,
                    'line2': null
                }]
            ])
        }
    },
    methods: {
        async contactFormDataGet(token) {
            const url = `${this.$api}/forms/contact/${token}`
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json;charset=UTF-8' 
                }
            }).then(response => {
                return response.json().then(result => {
                    return result
                })
            }).catch(() => {
                return null
            })
        },
        async dynamicFormDataGet(token) {
            const url = `${this.$api}/forms/dynamic/${token}`
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json;charset=UTF-8' 
                }
            }).then(response => {
                return response.json().then(result => {
                    return result
                })
            }).catch(() => {
                return null
            })
        },
        async dynamicFormPushData(person) {
            const url = `${this.$api}/forms/dynamic`
            return fetch(url, {
                method: 'PUT',
                body: JSON.stringify(person),
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json;charset=UTF-8' 
                }
            }).then(response => {
                return response.json().then(result => {
                    return result
                });
            }).catch(() => {
                return null
            })
        },
        async contactFormPushData(formData) {
            formData.token = this.token
            const url = `${this.$api}/forms/contact/update`
            return fetch(url, {
                method: 'PUT',
                body: JSON.stringify(formData),
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json;charset=UTF-8' 
                }
            }).then(response => {
                return response.json().then(result => {
                    return result
                });
            }).catch(() => {
                return null
            })
        },
        async verifyToken(token)
        {
            const url = `${this.$api}/forms/contact/verify`
            return await fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json;charset=UTF-8' 
                },
                body: JSON.stringify({'token': token})
            }).then(response => {
                return response.json().then(result => {
                    return result
                })
            }).catch(() => {
                return null
            })
        }
    }
}